import React, { useEffect } from "react";
import Layout from '../components/layout'
import ContactForm from '../components/contact-form'
import utils from "../components/utils";
import { DEFAULT_INNER_COMPONENT_DELAY_MS } from "../constants";


export default function ThankyouPage({ data }) {
  useEffect(() => {
    utils.resetPage()

    setTimeout(() => {
    }, DEFAULT_INNER_COMPONENT_DELAY_MS)
  }, [])

  return (
    <Layout>
      <section id="about" className="px-4 lg:px-0 min-h-screen flex items-center">
        <div className="max-w-2xl mx-auto">
          <h2 className="font-sailec lg:text-lg text-center">
            Thank you for your enquiry. We will be in touch shortly.
            </h2>
        </div>
      </section>

      <ContactForm />
    </Layout>
  )
}
